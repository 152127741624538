// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";

// export default function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }

// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";

// export default function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     const handleLinkClick = () => {
//       window.scrollTo(0, 50);
//       // window.screenTop
//     };

//     const links = document.querySelectorAll("a");
//     const footerLink = document.querySelectorAll("Link");


//     links.forEach(link => {
//       link.addEventListener("click", handleLinkClick);
//     });
//     footerLink.forEach(link => {
//       link.addEventListener("click", handleLinkClick);
//     });

//     return () => {
//       links.forEach(link => {
//         link.removeEventListener("click", handleLinkClick);
//       });
//       footerLink.forEach(link => {
//         link.removeEventListener("click", handleLinkClick);
//       });
//     };
//   }, [pathname]);

//   return null;
// }

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const handleLinkClick = () => {
      document.documentElement.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    const links = document.querySelectorAll("a");

    links.forEach(link => {
      link.addEventListener("click", handleLinkClick);
    });

    return () => {
      links.forEach(link => {
        link.removeEventListener("click", handleLinkClick);
      });
    };
  }, [pathname]);

  return null;
}

