import React from 'react';

const StaticStarRating = ({ totalStars, rating }) => {
  return (
    <div>
      {[...Array(totalStars)].map((star, index) => {
        const currentRating = index + 1;

        return (
          <span
            key={index}
            className="star text-center"
            style={{
              color: currentRating <= rating ? "#ffc107" : "#e4e5e9"
            }}
          >
            &#9733;
          </span>
        );
      })}
    </div>
  );
};

export default StaticStarRating;
